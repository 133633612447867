import React from 'react'
import BodyLayout from '../BodyLayout';
import TopNav from '../TopNav';

function AppLayout({ children }) {
    return (
        <main className="flex flex-column h-full w-full">
            <TopNav />
            <BodyLayout children={children} />
        </main>
    )
}

export default AppLayout