import React, { useState } from 'react'
import ComponentCard from '../../layout/ComponentCard'
import style from './inclusions.module.scss'
import ChipComp from '../../theme/Elements/ChipComp';

function Inclusions() {

    const ChipsData = {
        "Table black": ["NN", "bt1", "bt2", "bt3", "blank"],
        "Side black": ["NN", "BC1", "BC2", "bc3", "blank"],
        "Table white": ["NN", "t1", "t2", "t3", "blank"],
        "Side white": ["NN", "TO1", "TO2", "blank"],
        "Table open": ["NN", "TO1", "TO2", "blank"],
        "Crown open": ["NN", "PO1", "PO2", "blank"],
        "Pavilion open": ["NN", "TO1", "TO2", "blank"],
        "Girdle open": ["NN", "GO1", "GO2", "blank"],
    };

    const [selectedChips, setSelectedChips] = useState({});

    const handleChipClick = (category, chip) => {
        setSelectedChips((prevSelectedChips) => {
            const categorySelectedChips = prevSelectedChips[category] || [];
            if (categorySelectedChips.includes(chip)) {
                return {
                    ...prevSelectedChips,
                    [category]: categorySelectedChips.filter((c) => c !== chip)
                };
            } else {
                return {
                    ...prevSelectedChips,
                    [category]: [...categorySelectedChips, chip]
                };
            }
        });
    };

    return (
        <ComponentCard title='Inclusions' icon='inclusions-icon'>
            <div className={style["card-body"]}>
                {Object.keys(ChipsData).map((key, i) => (
                    <div key={i} className={style["body-child"]}>
                        <h6 className="input-label">{key}</h6>
                        <div className={style["chip-wrapper"]}>
                            {ChipsData[key].map((chip, index) => (
                                <ChipComp
                                    upperCase
                                    key={index}
                                    label={chip}
                                    active={selectedChips[key] && selectedChips[key].includes(chip)}
                                    onClick={() => handleChipClick(key, chip)}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </ComponentCard>
    )
}

export default Inclusions