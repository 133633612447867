import { Tooltip } from '@mui/material'
import React from 'react'
import './tooltip_comp.scss'

function TooltipComp({ children, label }) {

  return (
    <Tooltip
      arrow
      title={label}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

export default TooltipComp