import React, { createContext, useState } from 'react';

export const TabContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <TabContext.Provider value={{ tabIndex, setTabIndex }}>
      {children}
    </TabContext.Provider>
  );
};