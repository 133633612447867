import React, { useState } from 'react';
import ComponentCard from '../../layout/ComponentCard';
import style from './specification.module.scss';
import ChipComp from '../../theme/Elements/ChipComp';
import RadioComp from '../../theme/Elements/RadioComp';

function Specification() {
    const cardChipsData = {
        Cut: ["3EX", "ex", "vg", "g", "er", "fr", "p", "-"],
        Polish: ["ex", "vg", "g", "fr", "p"],
        Symmetry: ["ex", "vg", "g", "fr", "p"],
    };

    const ChipsData = {
        "Fls": ["NON", "FNT/SL", "MED", "STG", "VSTG", "VSL"],
        "BGM": ["NO BGM", "BROWN", "BGM", "MIXTINGE", "MILKY"],
        "Lab": ["GIA", "IGI", "HRD"],
        "Location": ["HONG KONG", "INDIA", "DUBAI", "BELGUIM", "USA", "TRANSIT", "OTHERS"],
        "Culet": ["NONE", "VSM", "SML", "MED", "OTHERS", "Blank"],
    };

    const [chips, setChips] = useState({
        cardChips: {},
        selectedChips: {},
    });

    const [isActive, setActive] = useState("");

    const handleChipClick = (type, category, chip) => {
        setChips((prevChips) => {
            const updatedChips = { ...prevChips[type] };
            const categoryChips = updatedChips[category] || [];
            if (categoryChips.includes(chip)) {
                updatedChips[category] = categoryChips.filter((c) => c !== chip);
            } else {
                updatedChips[category] = [...categoryChips, chip];
            }
            return { ...prevChips, [type]: updatedChips };
        });
    };

    const easySelect = (event) => {
        const item = event.target.value
        const isCurrentlySelected = Object.keys(chips.cardChips).some(key =>
            chips.cardChips[key]?.some(chip => chip.toLowerCase() === item.toLowerCase())
        );

        if (isCurrentlySelected) {
            setChips((prevChips) => ({ ...prevChips, cardChips: {} }));
            setActive("");
        } else {
            let itemsToSelect;
            if (item.toLowerCase() === '3ex') {
                itemsToSelect = ['ex'];
            } else if (item.toLowerCase() === '3vg+') {
                itemsToSelect = ['ex', 'vg'];
            } else {
                itemsToSelect = [item];
            }

            const selectedChips = itemsToSelect.reduce((acc, currentItem) => {
                Object.keys(cardChipsData).forEach(key => {
                    const chips = cardChipsData[key].filter(chip => chip.toLowerCase().includes(currentItem.toLowerCase()));
                    if (chips.length > 0) {
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key] = [...acc[key], ...chips];
                    }
                });
                return acc;
            }, {});

            setChips((prevChips) => ({ ...prevChips, cardChips: selectedChips }));
            setActive(item);
        }
    };


    const radioData = [
        {
            value: "3EX",
            label: "3EX"
        },
        {
            value: "3VG+",
            label: "3VG+"
        },
    ]

    return (
        <ComponentCard title='Specifications' icon='specification-details-icon'>
            <div className={style["content-container"]}>
                <div className={style["card-children-content"]}>
                    <div className={style["card-header"]}>
                        <RadioComp data={radioData} onChange={easySelect} active={isActive} />
                    </div>
                    <div className={style["card-body"]}>
                        {Object.keys(cardChipsData).map((key, i) => (
                            <div key={i} className={`${style["body-child"]} ${style["inner-child"]}`}>
                                <h6 className="input-label">{key}</h6>
                                <div className={style["chip-wrapper"]}>
                                    {cardChipsData[key].map((chip, index) => (
                                        <ChipComp
                                            upperCase
                                            key={index}
                                            label={chip}
                                            active={chips.cardChips[key] && chips.cardChips[key].includes(chip)}
                                            onClick={() => handleChipClick('cardChips', key, chip)}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={style["card-body"]}>
                    {Object.keys(ChipsData).map((key, i) => (
                        <div key={i} className={style["body-child"]}>
                            <h6 className="input-label">{key}</h6>
                            <div className={style["chip-wrapper"]}>
                                {ChipsData[key].map((chip, index) => (
                                    <ChipComp
                                        upperCase
                                        key={index}
                                        label={chip}
                                        active={chips.selectedChips[key] && chips.selectedChips[key].includes(chip)}
                                        onClick={() => handleChipClick('selectedChips', key, chip)}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </ComponentCard>
    );
}

export default Specification;
