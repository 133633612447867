import React, { useState, useRef, useEffect } from "react";
import style from "./home.module.scss";
import BasicDetails from "../../componnets/BasicDetails";
import DiamondDetails from "../../componnets/DiamondDetails";
import Specification from "../../componnets/Specification";
import Inclusions from "../../componnets/Inclusions";
import Financials from "../../componnets/Financials";
import Advance from "../../componnets/Advance";
import Parameters from "../../componnets/Parameters";
import ChipComp from "../../theme/Elements/ChipComp";
import ButtonComp from "../../theme/Elements/ButtonComp";
import { RefreshIcon } from "../../theme/icons";
import ModalComp from "../../theme/Elements/ModalComp";
import ModalTable from "../../theme/Elements/TableComp/ModalTable";


function Home() {

  const chipsData = [
    "Basic Details",
    "Diamond details",
    "Specifications",
    "Parameters",
    "Inclusions",
    "Advance",
    "Financials",
  ];
  const [isActive, setActive] = useState(0);
  const [filter, setFilter] = useState(false)
  const handleFilterClose = () => {
    setFilter(false)
  }
  const scrollTopHeight = 168;

  const sectionRefs = useRef(chipsData.map(() => React.createRef()));


  const handleTabClick = (index) => {
    setActive(index);
    const topOffset =
      sectionRefs.current[index]?.current?.getBoundingClientRect().top +
      window.pageYOffset -
      scrollTopHeight;
    window.scrollTo({ top: topOffset, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = scrollTopHeight; // Offset for fixed top bar and some buffer
      const currentScroll = window.pageYOffset + offset;

      const sectionIndex = sectionRefs.current.findIndex((ref) => {
        if (!ref.current) return false;
        const { offsetTop, offsetHeight } = ref.current;
        return (
          offsetTop <= currentScroll && offsetTop + offsetHeight > currentScroll
        );
      });

      if (sectionIndex !== -1 && sectionIndex !== isActive) {
        setActive(sectionIndex);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isActive]);

  useEffect(() => {
    const handleScroll = () => {
      const tableHeader = document.querySelector('.header-container');
      if (window.scrollY > 0) {
        tableHeader.classList.add('scrolled');
      } else {
        tableHeader.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`${style["header-container"]} header-container`}>
        <div className={style["left-section"]}>
          <h5 className={style["header-title"]}>Search</h5>
          <div className={style["chips-wrapper"]}>
            {chipsData.map((item, i) => {
              return (
                <ChipComp
                  onClick={() => handleTabClick(i)}
                  active={isActive === i}
                  light
                  rounded
                  font={14}
                  activeIndex={i}
                  key={i}
                  outlined
                  label={item}
                  className={style["custom-chip"]}
                />
              );
            })}
          </div>
        </div>
        <div className={style["right-section"]}>
          <ModalComp title="Search" open={filter} onClose={handleFilterClose} trigger={
            <ButtonComp counter={3} linkBtn onClick={() => setFilter(true)} primary children="Saved filters" height={40} />
          }>
            <div className={style["filter-modal-content"]}>
              <div className={style["table-wrapper"]}>
                <ModalTable />
              </div>
              <div className={style["button-wrapper"]}>
                <ButtonComp height={37} onClick={handleFilterClose} secondary>Cancel</ButtonComp>
                <ButtonComp height={37} onClick={handleFilterClose}>Save</ButtonComp>
              </div>
            </div>
          </ModalComp>
          <ButtonComp
            secondary
            children="Refresh"
            prefix={<RefreshIcon />}
            height={40}
          />
        </div>
      </div>
      <div className={`card-wrapper container container-class`}>
        <div ref={sectionRefs.current[0]}>
          <BasicDetails />
        </div>
        <div ref={sectionRefs.current[1]}>
          <DiamondDetails />
        </div>
        <div ref={sectionRefs.current[2]}>
          <Specification />
        </div>
        <div ref={sectionRefs.current[3]}>
          <Parameters />
        </div>
        <div ref={sectionRefs.current[4]}>
          <Inclusions />
        </div>
        <div ref={sectionRefs.current[5]}>
          <Advance />
        </div>
        <div ref={sectionRefs.current[6]}>
          <Financials />
        </div>
      </div>
    </>

  );
}

export default Home;
