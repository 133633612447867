import React, { useContext, useEffect, useState } from 'react'
import Home from './pages/Home';
import AppLayout from './layout/AppLayout/index';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { TabContext } from './context/GlobalProvider';
import Result from './pages/Result';

function App() {

  const { tabIndex } = useContext(TabContext)

  const router = createBrowserRouter([
    {
      path: "/",
      element: tabIndex === 1 ? <Home /> : <Result />,
    },
  ]);

  return (
    <AppLayout>
      <RouterProvider router={router} />
    </AppLayout>
  )
}

export default App