import { AgGridReact } from 'ag-grid-react';
import { useState } from 'react';
import './table_comp.scss'
import { TableDelete, TableEdit } from '../../icons';
import ButtonComp from '../ButtonComp';
function ModalTable() {

  const [rowData, setRowData] = useState([
    {
      Name: "Filter1", Filter: `1. Shape: round color: D
2. Shape: Pear color: G` },
    {
      Name: "Filter4", Filter: `1. Shape: round color: D
2. Shape: Pear color: G` },
    {
      Name: "Filter2", Filter: `1. Shape: round color: D
2. Shape: Pear color: G` },
  ]);
  const [colDefs, setColDefs] = useState([
    { field: "Name", width: 120 },
    {
      field: "Filter", width: 320, sortable: false, cellRenderer:
        function (params) {
          return (
            <pre className='pre-data'>
              {params.value}
            </pre>
          );
        }
    },
    {
      headerName: "Actions", width: 163, flex: 1, field: "action", cellRenderer: function (params) {
        return (
          <>
            <ButtonComp className="apply-btn" height={32}>Apply</ButtonComp>
            <span className="icon-container">
              <TableEdit />
            </span>
            <span className="icon-container">
              <TableDelete />
            </span>
          </>
        );
      }
    },
  ]);

  const customIcons = {
    sortAscending: `
<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_296_49109)">
<path d="M2.08594 6.66406L7.91927 6.66406L5.0026 2.08073L2.08594 6.66406Z" fill="#525967"/>
</g>
<g clipPath="url(#clip1_296_49109)">
<path d="M7.91406 8.33594L2.08073 8.33594L4.9974 12.9193L7.91406 8.33594Z" fill="#525967"/>
</g>
<defs>
<clipPath id="clip0_296_49109">
<rect width="10" height="10" fill="white" transform="matrix(0 -1 1 0 0 10)"/>
</clipPath>
<clipPath id="clip1_296_49109">
<rect width="10" height="10" fill="white" transform="matrix(0 1 -1 0 10 5)"/>
</clipPath>
</defs>
</svg>

  `,
    sortDescending: `
<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_296_49109)">
<path d="M2.08594 6.66406L7.91927 6.66406L5.0026 2.08073L2.08594 6.66406Z" fill="#525967"/>
</g>
<g clipPath="url(#clip1_296_49109)">
<path d="M7.91406 8.33594L2.08073 8.33594L4.9974 12.9193L7.91406 8.33594Z" fill="#525967"/>
</g>
<defs>
<clipPath id="clip0_296_49109">
<rect width="10" height="10" fill="white" transform="matrix(0 -1 1 0 0 10)"/>
</clipPath>
<clipPath id="clip1_296_49109">
<rect width="10" height="10" fill="white" transform="matrix(0 1 -1 0 10 5)"/>
</clipPath>
</defs>
</svg>

`
  }
  return (
    <div
      className="ag-theme-quartz"
      style={{ height: 400 }}
    >
      <AgGridReact
        rowHeight={55}
        className='modal-table-comp'
        rowData={rowData}
        columnDefs={colDefs}
        icons={customIcons}
      />
    </div>
  )
}

export default ModalTable