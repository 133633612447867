import React from 'react'
import ComponentCard from '../../layout/ComponentCard'
import InputComp from '../../theme/Elements/InputComp'
import SelectComp from '../../theme/Elements/SelectComp'
import style from './basic_details.module.scss'
import CheckboxSelect from '../../theme/Elements/CheckboxSelect'


function BasicDetails() {

    const SupplierNameData = [
        {
            value: "Ankit Gems PVT LTD-(s)",
            label: "Ankit Gems PVT LTD-(s)"
        },
        {
            value: "Aspeco (HK) Limited (s)",
            label: "Aspeco (HK) Limited (s)"
        },
        {
            value: "Dharam Creations(HK) LTD (S)",
            label: "Dharam Creations(HK) LTD (S)"
        },
        {
            value: "Excel Success Limited (s)",
            label: "Excel Success Limited (s)"
        },
        {
            value: "Dinestar Jewellery & Diamonds",
            label: "Dinestar Jewellery & Diamonds"
        },
        {
            value: "Harkrishnan exports PVT LTD",
            label: "Harkrishnan exports PVT LTD"
        },
        {
            value: "JB Jewels HK LTD",
            label: "JB Jewels HK LTD"
        },
    ]
    const SignData = [
        {
            value: "Plus (+)",
            label: "Plus (+)"
        },
        {
            value: "Minus (-)",
            label: "Minus (-)"
        },
    ]
    const DiscountData = [
        {
            value: "Discount",
            label: "Discount"
        },
        {
            value: "Value",
            label: "Value"
        },
    ]
    return (
        <ComponentCard title='Basic details' icon='basic-details-icon'>
            <div className={style["child-wrapper"]}>
                <CheckboxSelect className={style["supplier-select"]} placeholder="Search supplier" data={SupplierNameData} label="Supplier name" />
                <SelectComp className={style["supplier-select"]} placeholder="Select customer" label="Customer name " />
                <div className={style["select-wrapper"]}>
                    <SelectComp placeholder="Select type" label="Discount type" data={DiscountData} />
                    <SelectComp placeholder="Select sign" label="Sign" data={SignData} />
                    <InputComp labelPlacement="outer" fullWidth id="percentage" placeholder="Add percentage" label="Percentage" type="number" />
                </div>
            </div>
        </ComponentCard>
    )
}

export default BasicDetails