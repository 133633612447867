import { Dialog, DialogTitle } from '@mui/material';
import React, { useState } from 'react'
import './modal_comp.scss'
import { CloseIcon } from '../../icons';

function ModalComp(props) {

  const { children, trigger, open, onClose, title } = props

  return (
    <>
      {trigger}
      <Dialog open={open} className='modal-comp' onClose={onClose}>
        <div className="">
          <DialogTitle>{title}</DialogTitle>
          <span
            onClick={onClose}
            className="close-btn">
            <CloseIcon />
          </span>
        </div>
        {children}
      </Dialog>
    </>
  )
}

export default ModalComp