import React, { useEffect, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { TableImageIcon, TablePrintIcon, TableVideoIcon } from '../../../icons';
import style from './result_table.module.scss';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css"
import "ag-grid-charts-enterprise";
import { LicenseManager } from 'ag-grid-charts-enterprise';
import ColumnToolkit from './ColumnToolkit';
import SavedToolkit from './SavedToolkit';
import ChipComp from '../../ChipComp';
import ButtonComp from '../../ButtonComp';
import ModalComp from '../../ModalComp';
import RadioComp from '../../RadioComp';
import InputComp from '../../InputComp';
import FilterPopup from './FilterPopup';
import TooltipComp from '../../TooltipComp';
import CheckboxSelect from '../../CheckboxSelect';


LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064089}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{14 September 2024}____[v3]_[0102]_MTcyNjI2ODQwMDAwMA==d2dc20599115660f72d4d9cc6565d610");


function ResultTable() {

  const [rowData, setRowData] = useState(Array.from({ length: 22 }, () => ({
    stockId: 167024,
    lab: "GIA",
    shape: "EMERALD",
    ratio: 1.63,
    width: 3.73,
    tablePer: 57,
    depthPer: 61.4,
    crownAngle: 33.9,
    crownHeight: 13.63,
    pavilionAngle: 40.14,
    pavilionHeight: 43.69
  })));

  const columnDefs = [
    {
      headerName: "",
      field: 'select',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 44,
    },
    {
      headerName: "View", width: 107, field: "view", cellRenderer: function (params) {
        return (
          <>
            <span className="">
              <TableImageIcon />
            </span>
            <span className="">
              <TableVideoIcon />
            </span>
            <span className="">
              <TablePrintIcon />
            </span>
          </>
        );
      }
    },
    {
      headerName: "Stock ID", width: 144, field: "stockId", cellRenderer: function (params) {
        return (
          <>
            <span className="">{params.data.stockId}</span>
            <TooltipComp label="Price">
              <span className="dot dot-red"></span>
            </TooltipComp>
            <TooltipComp label="Price">
              <span className="dot dot-blue"></span>
            </TooltipComp>
            <TooltipComp label="Price">
              <span className="dot dot-brown"></span>
            </TooltipComp>
          </>
        )
      }
    },
    { headerName: "Lab", field: "lab", width: 99 },
    { headerName: "Shape", field: "shape", width: 117 },
    { headerName: "Ratio", field: "ratio", width: 109 },
    { headerName: "Width", field: "width", width: 114 },
    { headerName: "Table per", field: "tablePer", width: 137 },
    { headerName: "Depth per", field: "depthPer", width: 140 },
    { headerName: "Crown angle", field: "crownAngle", width: 159 },
    { headerName: "Crown height", field: "crownHeight", width: 163 },
    { headerName: "Pavilion angle", field: "pavilionAngle", width: 167 },
    { headerName: "Pavilion height", field: "pavilionHeight", width: 169 }
  ];

  const headerData = [
    {
      title: "1",
      sub: "PCS",
      src: "pcs"
    },
    {
      title: "3245",
      sub: "CTS",
      src: "cts"
    },
    {
      title: "83726.43",
      sub: "Rap Amount",
      src: "rapAmount"
    },
    {
      title: "23.45",
      sub: "Offer Disc (%)",
      src: "offerDisc"
    },
    {
      title: "354223",
      sub: "Offer Amount",
      src: "offerAmount"
    },
  ]

  const customIcons = {
    filter: `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" fill="#F3F3F3"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.19922 4.6C2.19922 4.44087 2.26243 4.28826 2.37495 4.17574C2.48748 4.06321 2.64009 4 2.79922 4H12.3992C12.5583 4 12.711 4.06321 12.8235 4.17574C12.936 4.28826 12.9992 4.44087 12.9992 4.6C12.9992 4.75913 12.936 4.91174 12.8235 5.02426C12.711 5.13679 12.5583 5.2 12.3992 5.2H2.79922C2.64009 5.2 2.48748 5.13679 2.37495 5.02426C2.26243 4.91174 2.19922 4.75913 2.19922 4.6ZM4.33282 7.8C4.33282 7.64087 4.39603 7.48826 4.50855 7.37574C4.62108 7.26321 4.77369 7.2 4.93282 7.2H10.2656C10.4247 7.2 10.5774 7.26321 10.6899 7.37574C10.8024 7.48826 10.8656 7.64087 10.8656 7.8C10.8656 7.95913 10.8024 8.11174 10.6899 8.22426C10.5774 8.33678 10.4247 8.4 10.2656 8.4H4.93282C4.77369 8.4 4.62108 8.33678 4.50855 8.22426C4.39603 8.11174 4.33282 7.95913 4.33282 7.8ZM6.53282 10.4C6.37369 10.4 6.22108 10.4632 6.10855 10.5757C5.99603 10.6883 5.93282 10.8409 5.93282 11C5.93282 11.1591 5.99603 11.3117 6.10855 11.4243C6.22108 11.5368 6.37369 11.6 6.53282 11.6H8.66562C8.82475 11.6 8.97736 11.5368 9.08988 11.4243C9.2024 11.3117 9.26562 11.1591 9.26562 11C9.26562 10.8409 9.2024 10.6883 9.08988 10.5757C8.97736 10.4632 8.82475 10.4 8.66562 10.4H6.53282Z" fill="#181D1F"/>
</svg>
`,
    saved: `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 4L10 8V10.5L7 13.5V8L3 4V3H14V4ZM4.5 4L8 7.5V11L9 10V7.5L12.5 4H4.5Z" fill="#181D1F" fill-opacity="0.9"/>
</svg>

`,
    columns: `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3334 2.56406H4.33341C3.86487 2.56406 3.41551 2.75019 3.08419 3.08151C2.75288 3.41282 2.56675 3.86218 2.56675 4.33073V11.6641C2.56675 12.1326 2.75288 12.582 3.08419 12.9133C3.41551 13.2446 3.86487 13.4307 4.33341 13.4307H12.3334C12.802 13.4307 13.2513 13.2446 13.5826 12.9133C13.914 12.582 14.1001 12.1326 14.1001 11.6641V4.33073C14.1001 3.86218 13.914 3.41282 13.5826 3.08151C13.2513 2.75019 12.802 2.56406 12.3334 2.56406ZM9.73342 3.43073V12.5641H6.93341V3.43073H9.73342ZM3.43341 11.6641V4.33073C3.43341 4.09203 3.52824 3.86312 3.69702 3.69433C3.8658 3.52555 4.09472 3.43073 4.33341 3.43073H6.06675V12.5641H4.33341C4.09472 12.5641 3.8658 12.4692 3.69702 12.3005C3.52824 12.1317 3.43341 11.9028 3.43341 11.6641ZM13.2334 11.6641C13.2334 11.9028 13.1386 12.1317 12.9698 12.3005C12.801 12.4692 12.5721 12.5641 12.3334 12.5641H10.6001V3.43073H12.3334C12.5721 3.43073 12.801 3.52555 12.9698 3.69433C13.1386 3.86312 13.2334 4.09203 13.2334 4.33073V11.6641Z" fill="#1A1A1A" stroke="black" strokeWidth="0.2"/>
</svg>
`
  };

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: ColumnToolkit,
        },
        {
          id: 'filters',
          labelDefault: 'Saved',
          labelKey: 'filters',
          iconKey: 'saved',
          toolPanel: SavedToolkit,
        }
      ],
    };
  }, []);

  const chips = [
    {
      key: "Shape",
      value: "Emerald"
    },
    {
      key: "Ratio",
      value: "1.63"
    },
    {
      key: "Width",
      value: "12.4"
    },
    {
      key: "Shape",
      value: "Emerald"
    },
    {
      key: "Ratio",
      value: "1.63"
    },
    {
      key: "Width",
      value: "12.4"
    },
    {
      key: "Shape",
      value: "Emerald"
    },
    {
      key: "Ratio",
      value: "1.63"
    },
    {
      key: "Width",
      value: "12.4"
    },
    {
      key: "Shape",
      value: "Emerald"
    },
    {
      key: "Ratio",
      value: "1.63"
    },
    {
      key: "Width",
      value: "12.4"
    },
  ]

  const [process, setProcess] = useState(false)
  const processClose = () => {
    setProcess(false)
  }
  const CompanySelect = [
    {
      value: "Ankit Gems PVT LTD-(s)",
      label: <div className={style["table-drop-container"]}>
        <h6 className={style["data-value"]}>Ankit Gems PVT LTD-(s)</h6>
        <h6 className={style["data-value"]}>Germany</h6>
        <h6 className={style["data-value"]}>A-GR-41</h6>
        <div className={style["avatar-container"]}>
          <img src="Images/svg/table-profile.svg" alt="profile" />
          <h6 className={style["data-value"]}>Dulce Saris</h6>
        </div>
      </div>
    },
    {
      value: "Ankit Gems PVT LTD-(d)",
      label: <div className={style["table-drop-container"]}>
        <h6 className={style["data-value"]}>Ankit Gems PVT LTD-(d)</h6>
        <h6 className={style["data-value"]}>Germany</h6>
        <h6 className={style["data-value"]}>A-GR-41</h6>
        <div className={style["avatar-container"]}>
          <img src="Images/svg/table-profile.svg" alt="profile" />
          <h6 className={style["data-value"]}>Dulce Saris</h6>
        </div>
      </div>
    },
    {
      value: "Ankit Gems PVT LTD-(f)",
      label: <div className={style["table-drop-container"]}>
        <h6 className={style["data-value"]}>Ankit Gems PVT LTD-(f)</h6>
        <h6 className={style["data-value"]}>Germany</h6>
        <h6 className={style["data-value"]}>A-GR-41</h6>
        <div className={style["avatar-container"]}>
          <img src="Images/svg/table-profile.svg" alt="profile" />
          <h6 className={style["data-value"]}>Dulce Saris</h6>
        </div>
      </div>
    },
  ]

  const statusSelect = [
    {
      value: "Hold",
      label: "Hold"
    },
    {
      value: "Order",
      label: "Order"
    },
    {
      value: "Release",
      label: "Release"
    },
  ]

  const [cart, setCart] = useState(false)
  const onCartClose = () => {
    setCart(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      const tableHeader = document.querySelector('.header-container');
      if (window.scrollY > 0) {
        tableHeader.classList.add('scrolled');
      } else {
        tableHeader.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={style["table-wrapper"]}>
      <div className={`${style["header-wrapper"]} header-container`}>
        <div className={style["data-wrapper"]}>
          {headerData.map((item, index) => {
            return (
              <>
                <div key={index} className={style["data-container"]}>
                  <span className={style["data-icon"]}>
                    <img src={`Images/svg/${item.src}.svg`} alt={item.sub} />
                  </span>
                  <div className={style["value-container"]}>
                    <h6 className={`${style["text"]} ${style[item.color]}`}>{item.sub}</h6>
                    <h4 className={`${style["value"]}`}>{item.title}</h4>
                  </div>
                </div>
              </>
            )
          })}
        </div>
        <div className={style["button-wrapper"]}>
          <ModalComp
            open={cart}
            title="Cart"
            onClose={onCartClose}
            trigger={
              <ButtonComp onClick={() => setCart(true)} height={37}>Add to cart</ButtonComp>
            }
          >
            <div className={style["process-content"]}>
              <CheckboxSelect data={CompanySelect} label='Company Name' placeholder="Search Company" />
              <InputComp label="Remarks" placeholder="Add Remarks" />
              <InputComp label="Validity Days" placeholder="Add Validity Days" type="number" />
              <div className={style["modal-footer"]}>
                <ButtonComp onClick={onCartClose} height={37} secondary bgNone>Cancel</ButtonComp>
                <ButtonComp onClick={onCartClose} height={37}>Add to Cart</ButtonComp>
              </div>
            </div>
          </ModalComp>
          <ModalComp
            open={process}
            title="Add to order process"
            onClose={processClose}
            trigger={
              <ButtonComp onClick={() => setProcess(true)} height={37} bgNone secondary>Process now</ButtonComp>
            }
          >
            <div className={style["process-content"]}>
              <CheckboxSelect data={CompanySelect} label='Company Name' placeholder="Search customer" />
              <CheckboxSelect placeholder="Search Status" data={statusSelect} label="Status" />
              <RadioComp checked data={[
                {
                  label: "Quality Check",
                  value: "check",
                  checked: true
                }
              ]} />
              <InputComp label="Remarks" placeholder="Add Remarks" />
              <div className={style["modal-footer"]}>
                <ButtonComp onClick={processClose} height={37} secondary bgNone>Cancel</ButtonComp>
                <ButtonComp onClick={processClose} height={37}>Continue</ButtonComp>
              </div>
            </div>
          </ModalComp>
          <ButtonComp bgNone secondary height={37}>Approve</ButtonComp>
        </div>
      </div>
      <div className={style["chips-section"]}>
        <h6 className={style["chips-title"]}>Selected filters:</h6>
        {
          chips.map((item, i) => (
            <ChipComp resultChip onDelete={() => { }} key={i} label={
              <>
                <span className={style["chip-key"]}>
                  {item.key}
                </span>:
                <span className={style["chip-value"]}>
                  {item.value}
                </span>
              </>
            } />
          ))
        }
        <ButtonComp className={style["clear-btn"]} linkBtn>Clear filters</ButtonComp>
      </div>
      <div className="ag-theme-quartz table-content-wrapper">
        <AgGridReact
          className='result-table'
          columnDefs={columnDefs}
          rowData={rowData}
          enableRangeSelection={true}
          rowSelection="multiple"
          defaultColDef={{
            sortable: true,
            resizable: false,
            filter: FilterPopup,
          }}
          icons={customIcons}
          sideBar={sideBar}
        />
      </div>
    </div>
  )
}

export default ResultTable