import React, { useRef, useState } from "react";
import { ControlledMenu, MenuItem, useHover } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import './side_nav_menu.scss'
import { NAvbarMenuIcon } from "../../icons";

// Custom hook to manage the menu state and behavior
function useMenu(ref) {
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  return {
    isOpen,
    setOpen,
    anchorProps,
    hoverProps,
  };
}

// Component to render each individual menu
function HoverMenu({ menu }) {
  const ref = useRef(null);
  const { isOpen, setOpen, anchorProps, hoverProps } = useMenu(ref);

  return (
    <>
      <div ref={ref} {...anchorProps}>
        <div className={`menu-trigger ${isOpen ? "active" : ""}`}>
          <h6 className="menu-label"> {menu.label}</h6>
          <span className="arrow-icon"><NAvbarMenuIcon /></span>
        </div>
      </div>
      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        direction="right"
      >
        {menu.items.map((item, index) => (
          <MenuItem key={index}>{item}</MenuItem>
        ))}
      </ControlledMenu>
    </>
  );
}

// Main component to render all menus
export default function SideNavMenu() {
  const menuData = [
    { id: 1, label: "Stock Availability", items: ["Morem ipsum ", "dolor sit amet",] },
    { id: 2, label: "Stock", items: ["Option 1", "Option 2", "Option 3"] },
    { id: 3, label: "Availability", items: ["Option 1", "Option 2", "Option 3"] },
  ];

  return (
    <div className="sidenav-menu">
      {menuData.map((menu) => (
        <HoverMenu key={menu.id} menu={menu} />
      ))}
    </div>
  );
}
