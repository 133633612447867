import React, { useState } from 'react'
import ComponentCard from '../../layout/ComponentCard'
import style from './advance.module.scss';
import ChipComp from '../../theme/Elements/ChipComp';
import { ImageIcon, VideoIcon } from '../../theme/icons';
import RadioSelect from '../../theme/Elements/SelectComp/RadioSelect';

function Advance() {

  const ChipsData = {
    "Cert type": ["TYPE 11A", "TYPE 11B", "TYPE 1A", "BLANK"],
    "Media": ["Images", "Videos"],
  };
  const [selectedChips, setSelectedChips] = useState({});
  const handleChipClick = (category, chip) => {
    setSelectedChips((prevSelectedChips) => {
      const categorySelectedChips = prevSelectedChips[category] || [];
      if (categorySelectedChips.includes(chip)) {
        return {
          ...prevSelectedChips,
          [category]: categorySelectedChips.filter((c) => c !== chip)
        };
      } else {
        return {
          ...prevSelectedChips,
          [category]: [...categorySelectedChips, chip]
        };
      }
    });
  };

  const KeySelectData = [
    { option: "Needle" },
    { option: "Cloud" },
    { option: "crystal" },
    { option: "twinning wisp" },
    { option: "feather" },
    { option: "knot" },
    { option: "blue ellipse" },
    { option: "+10X pinpoint" },
  ];
  const CommentsSelectData = [
    { option: "i love this gem." },
    { option: "like & love" },
    { option: "superb & gorgeous" },
    { option: "quiet gorgeous" },
    { option: "my name is khan. and i m shahrukh" },
    { option: "sweet one" },
    { option: "i love it" },
    { option: "like & love" },
  ];

  return (
    <ComponentCard title='Advance' icon='advance-icon'>
      <div className={style["content-wrapper"]}>
        <div className={style["select-wrapper"]}>
          <RadioSelect data={KeySelectData} label="Key to symbol" />
        </div>
        <div className={style["select-wrapper"]}>
          <RadioSelect data={CommentsSelectData} label="Lab comments" />
        </div>
        {Object.keys(ChipsData).map((key, i) => (
          <div key={i} className={style["body-child"]}>
            <h6 className="input-label">{key}</h6>
            <div className={style["chip-wrapper"]}>
              {ChipsData[key].map((chip, index) => (
                <ChipComp
                  key={index}
                  label={chip === "Images" ? <><ImageIcon />{chip}</> : chip === "Videos" ? <><VideoIcon />{chip}</> : chip}
                  active={selectedChips[key] && selectedChips[key].includes(chip)}
                  onClick={() => handleChipClick(key, chip)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </ComponentCard>
  )
}

export default Advance