import React, { useState } from 'react';
import style from './parameters.module.scss';
import InputComp from './../../theme/Elements/InputComp/index';
import ComponentCard from '../../layout/ComponentCard';
import ChipComp from '../../theme/Elements/ChipComp';
import { InputAddIcon, RadioCheckIcon, RadioUnCheckIcon } from '../../theme/icons';
import ButtonComp from '../../theme/Elements/ButtonComp';

function Parameters() {

  const [activeBlanks, setActiveBlanks] = useState({});

  const inputData = [
    { key: 1, title: "Length", add: true },
    { key: 2, title: "Width", add: true },
    { key: 3, title: "Depth%" },
    { key: 4, title: "Table%" },
    { key: 5, title: "Crown angle", blank: true },
    { key: 6, title: "Crown height%", blank: true },
    { key: 7, title: "Pavilion angle", blank: true },
    { key: 8, title: "Pavilion height" },
    { key: 9, title: "Ratio" },
    { key: 10, title: "Girdle", blank: true },
  ];

  const [values, setValues] = useState(
    inputData.reduce((acc, item) => {
      acc[item.title] = { from: "", to: "" };
      return acc;
    }, {})
  );

  const [chipData, setChipData] = useState([]);

  const handleInputChange = (title, field, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [title]: {
        ...prevValues[title],
        [field]: value
      }
    }));
  };

  const handleAddClick = (title) => {
    const { from, to } = values[title];
    if (from && to) {
      setChipData([...chipData, { title, from, to }]);
      setValues((prevValues) => ({
        ...prevValues,
        [title]: { from: "", to: "" }
      }));
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleBlankClick = (title) => {
    setActiveBlanks((prevState) => ({
      ...prevState,
      [title]: !prevState[title]
    }));
  };

  return (
    <ComponentCard title='Parameters' icon='parameters-icon'>
      <div className={style["content-wrapper"]}>
        {inputData.map((item, i) => (
          <div key={i} className={style["child-wrapper"]}>
            <div className={style["child-header"]}>
              <h6 className="input-label">{item.title}</h6>
              {item.blank && (
                <div
                  className="flex align-center gap-6 cursor-pointer"
                  onClick={() => handleBlankClick(item.title)}
                >
                  <span>
                    {activeBlanks[item.title] ? <RadioCheckIcon /> : <RadioUnCheckIcon />}
                  </span>
                  <span className="input-label font-medium">BLANK</span>
                </div>
              )}
            </div>
            <div className={style["input-container"]}>
              <div className={style["number-wrapper"]}>
                <InputComp
                  validaton
                  disabled={item.disabled}
                  id={`${item.title}-from`}
                  placeholder="From"
                  type="number"
                  value={values[item.title].from}
                  onChange={(e) =>
                    handleInputChange(item.title, "from", e.target.value)
                  }
                />
                <InputComp
                  validaton
                  disabled={item.disabled}
                  id={`${item.title}-to`}
                  placeholder="To"
                  type="number"
                  value={values[item.title].to}
                  onChange={(e) =>
                    handleInputChange(item.title, "to", e.target.value)
                  }
                />
                {item.add ? (
                  <ButtonComp className={style["add-btn"]} circle height={24} iconOnly onClick={() => handleAddClick(item.title)}>
                    <InputAddIcon />
                  </ButtonComp>
                )
                  : <span className={style["add-btn"]} />
                }
              </div>
              {item.add && chipData.length > 0 && (
                <div className={style["chip-wrapper"]}>
                  {chipData
                    .filter((data) => data.title === item.title)
                    .map((data, index) => (
                      <ChipComp
                        active
                        white
                        height={28}
                        key={index}
                        label={`${data.from}-${data.to}`}
                        onDelete={handleDeleteChip(data)}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </ComponentCard>
  );
}

export default Parameters;
