import React, { useState } from 'react'
import { default as ReactSelect, components } from "react-select";
import CheckBoxComp from '../CheckBoxComp/index';
import { SearchIcon, SelectDropdown } from '../../icons';
import './checkbox_select.scss'

const Option = (props) => {
  return (
    <components.Option {...props}>
      <CheckBoxComp checked={props.isSelected}
        onChange={() => null} />
      <label className='option-label'>{props.label}</label>
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const selectedValues = props
    .getValue()
    .map((val) => val.value)
    .join(", ");

  return (
    <components.ValueContainer {...props} className=''>
      <span className="search-icon"><SearchIcon /></span>
      {selectedValues && (
        <div className="selected-values">{selectedValues}</div>
      )}
      {children}
    </components.ValueContainer>
  );
};


const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator
      {...props}
      className="dropdown-indicator"
    >
      <SelectDropdown />
    </components.DropdownIndicator>
  );
};

function CheckboxSelect({ id, label, data = [], className, placeholder, open }) {

  const [state, setState] = useState({ optionSelected: null });

  const handleChange = (selected) => {
    setState({
      optionSelected: selected,
    });
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage className='empty-state' {...props}>
        No option
      </components.NoOptionsMessage>
    );
  };


  return (
    <div className={`checkbox-select ${className}`}>
      {label && <label className='input-label' htmlFor={`${label}-select`}>{label}</label>}
      <ReactSelect
        menuIsOpen={open}
        placeholder={placeholder}
        isClearable={false}
        options={data}
        isMulti
        id={`${label}-select`}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        classNamePrefix="react-select"
        className="select-container"
        components={{
          Option,
          MultiValue: () => null,
          ValueContainer,
          DropdownIndicator,
          NoOptionsMessage
        }}
        onChange={handleChange}
        value={state.optionSelected}
      />
    </div>
  )
}


export default CheckboxSelect