import React, { useState } from 'react';
import style from './result_table.module.scss';
import ButtonComp from '../../ButtonComp';
import { TableDrag } from '../../../icons';
import CheckBoxComp from '../../CheckBoxComp';
import { TextField } from '@mui/material';

const SavedToolkit = () => {
  const Data = [
    "Layout 1", "Layout 2", "Layout 3", "Layout 4", "Layout 5", "Layout 6", "Layout 7"
  ];

  const [input, setInput] = useState("Save layout")

  return (
    <div className={`${style["toolkit-wrapper"]} ${style["saved-toolkit"]}`}>
      <div className={style["toolkit-header"]}>
        <TextField onChange={(e) => {
          setInput(e.target.value)
        }} value={input} className='toolkit-input' />
        <ButtonComp height={25}>Add</ButtonComp>
      </div>
      {Data.map((item, i) => (
        <div key={i} className={style["child-container"]}>
          <div className={style["action-block"]}>
            <span className="cursor-pointer">
              <TableDrag />
            </span>
            <CheckBoxComp id={item} />
          </div>
          <h6 id={item} className={style["child-name"]}>
            {item}
          </h6>
        </div>
      ))}
    </div>
  );
};

export default SavedToolkit;
