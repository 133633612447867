import React from 'react'
import style from './component_card.module.scss';

function ComponentCard({ children, title = "CardTitle", icon = "basic-details-icon", className }) {
    return (
        <div className={style["card-container"]}>
            <div className={style["card-header"]}>
                <span className={style["card-icon"]}>
                    <img src={`Images/svg/${icon}.svg`} alt="" />
                </span>
                <h5 className={style["card-title"]}>{title}</h5>
            </div>
            {children}
        </div>
    )
}

export default ComponentCard