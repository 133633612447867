import React, { useState } from 'react';
import style from './result_table.module.scss';
import CheckBoxComp from '../../CheckBoxComp';
import { TableDrag, TableSelectArrow } from '../../../icons';
import { MenuItem, Select } from '@mui/material';

const ColumnToolkit = () => {
  const [input, setInput] = useState('');

  const Data = [
    "View", "Stock ID", "Lab", "Shape", "Location", "Pointer", "BGM", "Color", "Clarity", "CTS", "Rap Rate", "Rap Amount", "Offer Disc", "Price Per CTC", "Cut", "Polish", "SYMM", "FLS Intensity", "Ration", "Length", "Width"
  ];

  return (
    <div className={`${style["toolkit-wrapper"]} ${style["column-toolkit"]}`}>
      <div className={style["toolkit-header"]}>
        <CheckBoxComp />
        <Select
          className='toolkit-select'
          displayEmpty
          value={input}
          onChange={(event) => {
            setInput(event.target.value);
          }}
          renderValue={(selected) => {
            if (!selected) {
              return <em className='select-placeholder'>Input Text</em>;
            }
            return selected;
          }}
          IconComponent={() => <span className='select-arrow'><TableSelectArrow /></span>}
        >
          <MenuItem className="select-comp-popup" value="Value 1">Value 1</MenuItem>
          <MenuItem className="select-comp-popup" value="Value 2">Value 2</MenuItem>
        </Select>
      </div>
      <div className={style["child-wrapper"]}>
        {Data.map((item, i) => (
          <div key={i} className={style["child-container"]}>
            <div className={style["action-block"]}>
              <span className="cursor-pointer">
                <TableDrag />
              </span>
              <CheckBoxComp id={item} />
            </div>
            <h6 id={item} className={style["child-name"]}>
              {item}
            </h6>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnToolkit;
