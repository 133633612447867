import React, { useState } from 'react';
import ComponentCard from '../../layout/ComponentCard';
import style from './financials.module.scss';
import InputComp from '../../theme/Elements/InputComp';

function Financials() {

  const InputData = [
    "Disc (%)", "Amount", "Final price/Cts", "Max. disc(% max. slab)", "Max. amt.(% max. amt.)", "Max. price/Cts(max. slab)"
  ];

  const [values, setValues] = useState(
    InputData.reduce((acc, item) => {
      acc[item] = { from: "", to: "" };
      return acc;
    }, {})
  );

  const handleInputChange = (title, field, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [title]: {
        ...prevValues[title],
        [field]: value
      }
    }));
  };

  return (
    <ComponentCard title='Financials' icon='financial-icon'>
      <div className={style["financial-content"]}>
        {InputData.map((item) => (
          <div key={item} className={style["content-container"]}>
            <h6 className="input-label">{item}</h6>
            <div className={style["input-wrapper"]}>
              <InputComp
                validaton
                placeholder='From'
                type="number"
                value={values[item].from}
                onChange={(e) =>
                  handleInputChange(item, "from", e.target.value)
                }
              />
              <InputComp
                validaton
                placeholder='To'
                type="number"
                value={values[item].to}
                onChange={(e) =>
                  handleInputChange(item, "to", e.target.value)
                }
              />
            </div>
          </div>
        ))}
      </div>
    </ComponentCard>
  );
}

export default Financials;
