import React from 'react'
import PropTypes from 'prop-types'
import './select_comp.scss'
import { FormControl, MenuItem, Select } from '@mui/material'
import { SelectDropdown } from '../../icons'

function SelectComp({ label, data = [], className, placeholder }) {
  return (
    <FormControl fullWidth className={`select-comp ${className}`}>
      <label className='input-label' htmlFor={`${label}-select`}>{label}</label>
      <Select
        displayEmpty
        id={`${label}-select`}
        IconComponent={() => <span className='select-arrow'><SelectDropdown /></span>}
        renderValue={(selected) => {
          if (!selected) {
            return <em className='select-placeholder'>{placeholder}</em>;
          }
          return selected;
        }}
      >
        {data.length === 0 ? (
          <MenuItem className='select-empty-state' value="" disabled>
            No option
          </MenuItem>
        ) : (
          data.map((item) => (
            <MenuItem className='select-comp-popup' key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
}

SelectComp.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
}

SelectComp.defaultProps = {
  data: [],
  placeholder: 'Select an option',
}

export default SelectComp
