import React, { useState } from 'react';
import ComponentCard from '../../layout/ComponentCard';
import style from './diamond_details.module.scss';
import { AsscherIcon, CushionIcon, EmeraldIcon, HeartIcon, InputAddIcon, MarquiseIcon, OvalIcon, PearIcon, PrincessIcon, RadiantIcon, RoundIcon, TickIcon, TriangleIcon } from '../../theme/icons';
import TabComp from '../../theme/Elements/TabComp';
import ChipComp from './../../theme/Elements/ChipComp/index';
import InputComp from '../../theme/Elements/InputComp';
import ButtonComp from '../../theme/Elements/ButtonComp';
import SelectComp from '../../theme/Elements/SelectComp';

function DiamondDetails() {
    const [selectedDiamonds, setSelectedDiamonds] = useState([]);

    const DiamondData = [
        {
            key: 1,
            name: "asscher",
            icon: <AsscherIcon />,
        },
        {
            key: 2,
            name: "cushion",
            icon: <CushionIcon />,
        },
        {
            key: 3,
            name: "emerald",
            icon: <EmeraldIcon />,
        },
        {
            key: 4,
            name: "Heart",
            icon: <HeartIcon />,
        },
        {
            key: 5,
            name: "Marquise",
            icon: <MarquiseIcon />,
        },
        {
            key: 6,
            name: "Oval",
            icon: <OvalIcon />,
        },
        {
            key: 7,
            name: "pear",
            icon: <PearIcon />,
        },
        {
            key: 8,
            name: "Princess",
            icon: <PrincessIcon />,
        },
        {
            key: 9,
            name: "Radiant",
            icon: <RadiantIcon />,
        },
        {
            key: 10,
            name: "Round",
            icon: <RoundIcon />,
        },
        {
            key: 11,
            name: "Triangle",
            icon: <TriangleIcon />,
        },
        {
            key: 12,
            name: "others",
            icon: <AsscherIcon />,
        },
    ];

    const handleCardClick = (name) => {
        setSelectedDiamonds((prevSelected) =>
            prevSelected.includes(name)
                ? prevSelected.filter((item) => item !== name)
                : [...prevSelected, name]
        );
    };

    const handleSelectAllClick = () => {
        if (selectedDiamonds.length === DiamondData.length) {
            setSelectedDiamonds([]);
        } else {
            setSelectedDiamonds(DiamondData.map((item) => item.name));
        }
    };

    const isSelected = (name) => selectedDiamonds.includes(name);

    const [selectedChips, setSelectedChips] = useState({});

    const handleChipClick = (category, chip) => {
        setSelectedChips((prevSelectedChips) => {
            const categorySelectedChips = prevSelectedChips[category] || [];
            if (categorySelectedChips.includes(chip)) {
                return {
                    ...prevSelectedChips,
                    [category]: categorySelectedChips.filter((c) => c !== chip)
                };
            } else {
                return {
                    ...prevSelectedChips,
                    [category]: [...categorySelectedChips, chip]
                };
            }
        });
    };

    const ChipData = {
        GeneralCarat: ["2.00-2.20", "2.10-2.40", "2.30-2.80", "3.10-3.20", "3.10-3.40", "3.20-3.40", "2.00-2.40", "2.30-2.40", "2.40-2.40", "2.00-2.60", "2.20-2.40", "2.40-2.80", "3.00-3.20", "3.00-3.40", "3.00-3.60"],
        RegularColor: ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "OTHERS"],
        clarityChips: ["if", "il", "vvs1", "vvs2", "vs1", "vs2", "si1", "i1", "i2", "13"],
    }

    const inputData = [
        {
            key: 1,
            title: "custom",
        },
    ]

    const [values, setValues] = React.useState(
        inputData.reduce((acc, item) => {
            acc[item.title] = { from: "", to: "" };
            return acc;
        }, {})
    );
    const handleInputChange = (title, field, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [title]: {
                ...prevValues[title],
                [field]: value
            }
        }));
    };
    const [chipData, setChipData] = React.useState([]);
    const handleAddClick = (title) => {
        const { from, to } = values[title];
        if (from && to) {
            setChipData([...chipData, { title, from, to }]);
            setValues((prevValues) => ({
                ...prevValues,
                [title]: { from: "", to: "" }
            }));
        }
    };

    const handleDeleteChip = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
    };
    const caratTab = [
        {
            label: 'General', content: <div className={style["chip-wrapper"]}>
                {
                    ChipData.GeneralCarat.map((item, i) => (
                        <ChipComp active={selectedChips["GeneralCarat"] && selectedChips["GeneralCarat"].includes(item)}
                            onClick={() => handleChipClick("GeneralCarat", item)} key={i} label={item} />
                    ))
                }
            </div>
        },
        {
            label: 'Custom', content: <div className={style["input-container"]}>
                <div className={style["number-wrapper"]}>
                    <div className={style["select-container"]}>
                        <InputComp
                            validaton
                            id="from"
                            placeholder="From"
                            type="number"
                            value={values["custom"].from}
                            onChange={(e) =>
                                handleInputChange("custom", "from", e.target.value)
                            }
                        />
                    </div>
                    <div className={style["select-container"]}>
                        <InputComp
                            validaton
                            id="to"
                            placeholder="To"
                            type="number"
                            value={values["custom"].to}
                            onChange={(e) =>
                                handleInputChange("custom", "to", e.target.value)
                            }
                        />
                    </div>
                    <ButtonComp className={style["add-btn"]} circle height={24} iconOnly onClick={() => handleAddClick("custom")}>
                        <InputAddIcon />
                    </ButtonComp>
                </div>
                <div className={style["chip-wrapper"]}>
                    {chipData
                        .filter((data) => data.title === "custom")
                        .map((data, index) => (
                            <ChipComp
                                height={28}
                                key={index}
                                label={`${data.from}-${data.to}`}
                                onDelete={handleDeleteChip(data)}
                            />
                        ))}
                </div>
            </div>
        },
    ];
    const colorTab = [
        {
            label: 'Regular', content: <div className={style["chip-wrapper"]}>
                {
                    ChipData.RegularColor.map((item, i) => (
                        <ChipComp active={selectedChips["RegularColor"] && selectedChips["RegularColor"].includes(item)}
                            onClick={() => handleChipClick("RegularColor", item)} key={i} label={item} />
                    ))
                }
            </div>
        },
        {
            label: 'Fancy', content: (
                <div className="flex align-center gap-4">
                    <SelectComp label='Key To Symbol' placeholder="Select key" />
                    <SelectComp label='Key To Symbol' placeholder="Select key" />
                    <SelectComp label='Key To Symbol' placeholder="Select key" />
                </div>
            )
        },
    ];

    return (
        <ComponentCard title='Diamond details' icon='diamond-details-icon'>
            <div className={style["content-wrapper"]}>
                <div className={style["diamond-section"]}>
                    <div className={style["title-wrapper"]}>
                        <h5 className="input-label">Shape</h5>
                        <ButtonComp linkBtn onClick={handleSelectAllClick} className={style["select-all-btn"]}>
                            {
                                selectedDiamonds.length == 12 ? "Deselect all   " : "Select all"
                            }
                        </ButtonComp>
                    </div>
                    <div className={style["card-wrapper"]}>
                        {DiamondData.map((item) => (
                            <div
                                key={item.key}
                                className={`${style["card-container"]} ${isSelected(item.name) ? style["active"] : ""}`}
                                onClick={() => handleCardClick(item.name)}
                            >
                                <span className={style["image-container"]}>
                                    {item.icon}
                                </span>
                                <h6 className={style["card-text"]}>{item.name}</h6>
                                {isSelected(item.name) && (
                                    <span className={style["tick-icon"]}>
                                        <TickIcon />
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={style["carat-section"]}>
                    <div className={style["tab-section"]}>
                        <TabComp tabs={caratTab} prefix="Carat" />
                    </div>
                    <InputComp placeholder="Enter stock Id details" label="Stock Id detail" labelPlacement='outer' textArea />
                </div>
                <div className={style["carat-section"]}>
                    <div className={style["tab-section"]}>
                        <TabComp tabs={colorTab} prefix="Color" />
                    </div>
                    <div className={style["clarity-section"]}>
                        <h6 className="input-label">Clarity:</h6>
                        <div className={style["chip-wrapper"]}>
                            {
                                ChipData.clarityChips.map((item, i) => {
                                    return (
                                        <ChipComp active={selectedChips["clarityChips"] && selectedChips["clarityChips"].includes(item)}
                                            onClick={() => handleChipClick("clarityChips", item)} upperCase key={i} label={item} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ComponentCard>
    );
}

export default DiamondDetails;
