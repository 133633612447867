import { useGridFilter } from "ag-grid-react";
import { useCallback, useState } from "react";
import style from './result_table.module.scss'
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { FilterPicIcon, FilterTabIcon, GridTabIcon, LinesTabIcon } from './../../../icons';
import InputComp from "../../InputComp";
import CheckBoxComp from "../../CheckBoxComp";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default ({ model, onModelChange, getValue }) => {
  const doesFilterPass = useCallback(({ node }) => {
    return getValue(node).contains(model);
  }, [model]);

  useGridFilter({ doesFilterPass });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filterData = ["Select All", "10,000.00", "10,800.00", "11,400.00", "12,200.00", "12,600.00"]

  const GridFilter = [
    "Select Box", "View", "Stock Id", "Shape", "Location", "Pointing", "BGM"
  ]

  const LineFilter = [
    {
      icon: <FilterPicIcon />,
      label: "Pin Column"
    },
    {
      icon: "",
      label: "Auto size This Columns"
    },
    {
      icon: "",
      label: "Auto size All Columns"
    },
    {
      icon: "",
      label: "Reset Columns"
    },
  ]
  const tabData = [
    {
      icon: <FilterTabIcon />,
      content: (
        <div className={style["filter-data-wrapper"]}>
          {
            filterData.map((item, i) => (
              <div className={style["data-container"]} key={i}>
                <CheckBoxComp />
                <h6 className={style["filter-text"]}>{item}</h6>
              </div>
            ))
          }
        </div>
      )
    },
    {
      icon: <LinesTabIcon />,
      content: (
        <div className={style["filter-data-wrapper"]}>
          {
            LineFilter.map((item, i) => (
              <div className={`${style["data-container"]} gap-4 ${i === 0 || i === 2 ? style["item-border"] : ""}`} key={i}>
                <span className={style["filter-icon"]}>
                  {item.icon}
                </span>
                <h6 className={style["filter-text"]}>{item.label}</h6>
              </div>
            ))
          }
        </div>
      )
    },
    {
      icon: <GridTabIcon />,
      content: (
        <div className={style["filter-data-wrapper"]}>
          {
            GridFilter.map((item, i) => (
              <div className={style["data-container"]} key={i}>
                <CheckBoxComp />
                <h6 className={style["filter-text"]}>{item}</h6>
              </div>
            ))
          }
        </div>
      )
    },
  ]

  return (
    <div className='result-tab-comp-wrapper'>
      <Tabs value={value} className='result-tab-comp' onChange={handleChange} aria-label="icon tabs example">
        {tabData.map((tab, index) => (
          <Tab aria-label={index} icon={tab.icon} {...a11yProps(index)} key={index} />
        ))}
      </Tabs>
      <div className="filter-header">
        <InputComp placeholder="Search" />
      </div>
      {
        tabData.map((tab, index) => (
          <TabPanel value={value} index={index} key={index}>
            {tab.content}
          </TabPanel>
        ))
      }
    </div >
  );
}