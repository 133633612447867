import React from 'react'
import './toaster.scss'
import { Alert, AlertTitle, Slide, Snackbar } from '@mui/material';
import { DocumentIcon, ErrorIcon, InfoIcon, SuccessIcon } from '../../icons';

function ToasterComp({ open, setOpen, variant, desc }) {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen("");
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="bottom" />;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      action={null}
      className='toaster-comp'
    >
      <Alert
        onClose={handleClose}
        iconMapping={{
          success: <SuccessIcon />,
          error: <ErrorIcon />,
          info: <InfoIcon />,
          doc: <DocumentIcon />
        }}
        severity={variant}
        sx={{ width: '100%' }}
        className='toaster-comp-alert'
      >
        <AlertTitle>
          {
            variant === "doc" ? "Downloading in progress..." : variant
          }
        </AlertTitle>
        {desc}
      </Alert>
    </Snackbar>
  )
}

export default ToasterComp