import React, { useState } from 'react'
import style from './result.module.scss';
import ModalComp from '../../theme/Elements/ModalComp';
import ButtonComp from '../../theme/Elements/ButtonComp';
import { DownloadIcon, RefreshIcon, ShareIcon, TableSelectArrow } from '../../theme/icons';
import MenuComp from '../../theme/Elements/MenuComp';
import ResultTable from '../../theme/Elements/TableComp/ResultTable/ResultTable';
import { InputAdornment, MenuItem, OutlinedInput, Select } from '@mui/material';

function Result() {

  // download
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const DocumentData = [
    { name: "Supplier", },
    { name: "Admin", },
    { name: "Dealer", },
    { name: "Supplier", },
  ]
  const open = Boolean(anchorEl);

  // share
  const [share, setShare] = useState(false)
  const handleShareClose = () => {
    setShare(false)
  }

  const socialData = [
    {
      key: 1,
      name: "mail",
      icon: "mail"
    },
    {
      key: 2,
      name: "whatsapp",
      icon: "whatsapp"
    },
    {
      key: 3,
      name: "facebook",
      icon: "facebook"
    },
    {
      key: 4,
      name: "twitter",
      icon: "twitter"
    }
  ]

  const [discount, setDiscount] = useState("")
  const [type, setType] = useState("")
  const [sign, setSign] = useState("")

  return (
    <section className={style["page-container"]}>
      <div className={style["header-wrapper"]}>
        <div className={style["left-section"]}>
          <h4 className={style["section-title"]}>Result -1</h4>
          <div className={style["option-wrapper"]}>
            <div className={style["select-wrapper"]}>
              <Select
                className='result-select'
                displayEmpty
                value={discount}
                onChange={(event) => {
                  setDiscount(event.target.value);
                }}
                renderValue={(discount) => {
                  if (!discount) {
                    return <em className='select-placeholder'>Select discount</em>;
                  }
                  return discount;
                }}
                IconComponent={() => <span className='select-arrow'><TableSelectArrow /></span>}
              >
                <MenuItem className="select-comp-popup" value={"Discount"}>Discount</MenuItem>
                <MenuItem className="select-comp-popup" value={"Values"}>Values</MenuItem>
              </Select>
              <Select
                className='result-select'
                displayEmpty
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
                renderValue={(discount) => {
                  if (!discount) {
                    return <em className='select-placeholder'>Base type</em>;
                  }
                  return discount;
                }}
                IconComponent={() => <span className='select-arrow'><TableSelectArrow /></span>}
              >
                <MenuItem className="select-comp-popup" value="Type 1">Type 1</MenuItem>
                <MenuItem className="select-comp-popup" value="Type 2">Type 2</MenuItem>
              </Select>
              <Select
                className='result-select'
                displayEmpty
                value={sign}
                onChange={(event) => {
                  setSign(event.target.value);
                }}
                renderValue={(discount) => {
                  if (!discount) {
                    return <em className='select-placeholder'>Sign</em>;
                  }
                  return discount;
                }}
                IconComponent={() => <span className='select-arrow'><TableSelectArrow /></span>}
              >
                <MenuItem className="select-comp-popup" value="Sign 1">Sign 1</MenuItem>
                <MenuItem className="select-comp-popup" value="Sign 2">Sign 2</MenuItem>
              </Select>
            </div>
            {
              discount === "Discount" && <div className={style["input-container"]}>
                <OutlinedInput
                  className='result-input'
                  placeholder='00'
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
                <ButtonComp height={32}>Add</ButtonComp>
              </div>
            }
          </div>
        </div>
        <div className={style["right-section"]}>
          <MenuComp
            anchorOrigin={
              {
                vertical: 'bottom',
                horizontal: 'right',
              }
            }
            tramsformOrigin={
              {
                vertical: 'top',
                horizontal: 'right',
              }
            }
            id="documentMenu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            data={DocumentData}
          >
            <ButtonComp
              secondary
              className="download-btn"
              ariaControls={open ? 'documentMenu' : undefined}
              ariahaspopup
              ariaExpanded={open ? 'true' : undefined}
              onClick={handleClick}
              iconOnly
              height={40}
            ><DownloadIcon color='#5085A5' /></ButtonComp>
          </MenuComp>
          <ModalComp title="Share via" open={share} onClose={handleShareClose} trigger={
            <ButtonComp secondary className="share-btn" height={40} onClick={() => setShare(true)} iconOnly><ShareIcon color='#A87B09' /></ButtonComp>
          }>
            <div className={style["share-modal-content"]}>
              <div className={style["social-wrapper"]}>
                {
                  socialData.map((item, i) => {
                    return (
                      <div key={i} className={style["social-container"]}>
                        <span className={`${style["social-icon"]} ${style[item.name === 'mail' ? "mail" : item.name === "whatsapp" ? "whatsapp" : ""]}`}>
                          <img src={`Images/svg/${item.icon}.svg`} alt={item.icon} />
                        </span>
                        <h6 className={style["social-name"]}>{item.name}</h6>
                      </div>
                    )
                  })
                }
              </div>
              <div className={style["button-wrapper"]}>
                <ButtonComp onClick={handleShareClose} height={37} secondary>Cancel</ButtonComp>
                <ButtonComp onClick={handleShareClose} height={37}>Share</ButtonComp>
              </div>
            </div>
          </ModalComp>
          <ButtonComp bgNone secondary height={40}>Clear all</ButtonComp>
          <ButtonComp bgNone secondary height={40}>Modify search</ButtonComp>
        </div>
      </div>
      <div className={style["table-outside-wrapper"]}>
        <ResultTable />
      </div>
    </section>
  )
}

export default Result