import { useEffect } from "react";

const useOutsideClick = (ref, handler) => {
  const handleOutsideClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handler();
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [ref, handler]);
};

export default useOutsideClick;
