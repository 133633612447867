import React, { useContext, useState } from 'react'
import style from './footer.module.scss'
import ButtonComp from '../../theme/Elements/ButtonComp'
import { DownloadIcon, FooterAddIcon, FooterSearchIcon, SaveIcon, ShareIcon } from '../../theme/icons'
import ModalComp from '../../theme/Elements/ModalComp'
import MenuComp from '../../theme/Elements/MenuComp'
import InputComp from '../../theme/Elements/InputComp'
import { TabContext } from '../../context/GlobalProvider'
import ToasterComp from '../../theme/Elements/ToasterComp'

function Footer() {

    const { setTabIndex } = useContext(TabContext)

    const [toaster, setToaster] = useState(null)
    const handleToaster = (key) => {
        switch (key) {
            case "success":
                return setToaster("success")
            case "error":
                return setToaster("error")
            case "info":
                return setToaster("info")
            default:
                return setToaster("doc")
        }
    }

    // download
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const DocumentData = [
        {
            name: "Supplier",
            onClick: () => handleToaster("success")
        },
        {
            name: "Admin",
            onClick: () => handleToaster("error")
        },
        {
            name: "Dealer",
            onClick: () => handleToaster("info")
        },
        {
            name: "Supplier",
            onClick: () => handleToaster("doc")
        },
    ]
    const open = Boolean(anchorEl);

    // share
    const [share, setShare] = useState(false)
    const handleShareClose = () => {
        setShare(false)
    }
    const [saveFilter, setSaveFilter] = useState(false)
    const handleSaveFilterClose = () => {
        setSaveFilter(false)
    }
    const socialData = [
        {
            key: 1,
            name: "mail",
            icon: "mail"
        },
        {
            key: 2,
            name: "whatsapp",
            icon: "whatsapp"
        },
        {
            key: 3,
            name: "facebook",
            icon: "facebook"
        },
        {
            key: 4,
            name: "twitter",
            icon: "twitter"
        }
    ]

    return (
        <>
            <footer className={style["footer-container"]}>
                <p className={style["selected-text"]}>12 Filters Selected</p>
                <div className={style["btn-wrapper"]}>
                    <MenuComp
                        anchorOrigin={
                            {
                                vertical: 'top',
                                horizontal: 'center',
                            }
                        }
                        tramsformOrigin={
                            {
                                vertical: 'bottom',
                                horizontal: 'center',
                            }
                        }
                        id="documentMenu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        data={DocumentData}
                    >
                        <ButtonComp
                            secondary
                            height={40}
                            className="download-btn"
                            ariaControls={open ? 'documentMenu' : undefined}
                            ariahaspopup
                            ariaExpanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            iconOnly
                        ><DownloadIcon color='#5085A5' /></ButtonComp>
                    </MenuComp>
                    <ModalComp title="Share via" open={share} onClose={handleShareClose} trigger={
                        <ButtonComp secondary className="share-btn" height={40} onClick={() => setShare(true)} iconOnly><ShareIcon color='#A87B09' /></ButtonComp>
                    }>
                        <div className={style["share-modal-content"]}>
                            <div className={style["social-wrapper"]}>
                                {
                                    socialData.map((item, i) => {
                                        return (
                                            <div key={i} className={style["social-container"]}>
                                                <span className={`${style["social-icon"]} ${style[item.name === 'mail' ? "mail" : item.name === "whatsapp" ? "whatsapp" : ""]}`}>
                                                    <img src={`Images/svg/${item.icon}.svg`} alt={item.icon} />
                                                </span>
                                                <h6 className={style["social-name"]}>{item.name}</h6>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={style["button-wrapper"]}>
                                <ButtonComp onClick={handleShareClose} height={37} secondary>Cancel</ButtonComp>
                                <ButtonComp onClick={handleShareClose} height={37}>Share</ButtonComp>
                            </div>
                        </div>
                    </ModalComp>
                    <ButtonComp className="filter-btn" height={40} prefix={<FooterAddIcon color="#BF40BA" />} bgNone secondary>Add to filter</ButtonComp>
                    <ModalComp title="Save & search" maxWidth="sm" open={saveFilter} onClose={handleSaveFilterClose} trigger={
                        <ButtonComp bgNone className="save-btn" height={37} onClick={() => setSaveFilter(true)} prefix={<SaveIcon color="#167E46" />} secondary>Save filters</ButtonComp>
                    }>
                        <div className={style["input-modal-content"]}>
                            <InputComp placeholder="Name" />
                            <div className={style["button-wrapper"]}>
                                <ButtonComp onClick={handleSaveFilterClose} height={37} secondary>Cancel</ButtonComp>
                                <ButtonComp onClick={handleSaveFilterClose} height={37}>Save</ButtonComp>
                            </div>
                        </div>
                    </ModalComp>
                    <ButtonComp prefix={<FooterSearchIcon />} onClick={() => setTabIndex(2)} height={40}>Generate Result</ButtonComp>
                </div>
            </footer>
            <ToasterComp desc={
                toaster === "success" ? "This is a success alert with an encouraging title." : toaster === "error" ? "This is an error alert" : toaster === "info" ? "This is an info alert" : "Please wait until the download is complete."
            } variant={toaster} open={toaster} setOpen={setToaster} />
        </>
    )
}

export default Footer