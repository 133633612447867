import React, { useContext, useState, useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TabContext } from "../../../context/GlobalProvider";
import { TabCloseIcon } from "../../icons";

function DraggableTab() {
  const { tabIndex, setTabIndex } = useContext(TabContext);
  const [tabs, setTabs] = useState([
    { id: 1, label: "Search", icon: "customer-price" },
    { id: 2, label: "Result-1", icon: "result" },
  ]);


  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const newTabs = Array.from(tabs);
    const [removed] = newTabs.splice(result.source.index, 1);
    newTabs.splice(result.destination.index, 0, removed);
    setTabs(newTabs);
    setTabIndex(newTabs[result.destination.index].id);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(tabs[newValue].id);
  };

  return (
    <div className="draggable-tab">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tabs" direction="horizontal">
          {(provided) => (
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              ref={provided.innerRef}
              {...provided.droppableProps}
              value={tabs.findIndex((tab) => tab.id === tabIndex)}
              onChange={handleChange}
            >
              {tabs.map(({ id, label, icon }, index) => (
                <Draggable
                  key={id}
                  draggableId={`id-${id}`}
                  index={index}
                  disableInteractiveElementBlocking={true}
                >
                  {(provided) => (
                    <Tab
                      icon={<span><img src={`Images/svg/${icon}.svg`} alt="" /></span>}
                      className={tabIndex === id ? "active-tab" : ""}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      label={
                        <>
                          {label}
                          <span><TabCloseIcon /></span>
                        </>
                      }
                      onClick={() => setTabIndex(id)}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Tabs>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default DraggableTab;
