import React from 'react'
import './menu_comp.scss'
import { Menu, MenuItem } from '@mui/material'

function MenuComp({ anchorOrigin, tramsformOrigin, onClose, open, children, anchorEl, id, data, className = "menu-comp" }) {

  return (
    <>
      {children}
      <Menu
        anchorOrigin={anchorOrigin}
        transformOrigin={tramsformOrigin}
        keepMounted
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        className={className}
      >
        {
          data.map((item, i) => {
            return (
              <MenuItem key={i} onClick={() => {
                onClose()
                if (item.onClick) {
                  item.onClick();
                }
              }}>{item.name}</MenuItem>
            )
          })
        }
      </Menu>
    </>
  )
}

export default MenuComp