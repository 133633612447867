import React, { useContext } from 'react'
import Footer from '../Footer'
import SideNav from '../SideNav';
import { TabContext } from '../../context/GlobalProvider';

function BodyLayout({ children }) {

    const { tabIndex } = useContext(TabContext)

    return (
        <div className="flex flex-fit">
            <SideNav />
            <div className="w-full flex flex-column justify-between ml-70">
                {children}
                {
                    tabIndex === 1 && <Footer />
                }
            </div>
        </div >
    )
}

export default BodyLayout