import React, { useRef, useState } from 'react';
import style from './side_nav.module.scss';
import { AccordionDrop, DataMenuIcon, GridIcon, HamburgerIcon, NAvbarMenuIcon, NavbarClose, NestAccordionDrop, PageIcon } from '../../theme/icons';
import useOutsideClick from '../../hook/useOutsideClick';
import { Accordion, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import './side_nav.scss'
import SideNavMenu from '../../theme/Elements/SideNavMenu';


function SideNav() {

    const [isExpand, setExpand] = useState(false);

    const toggleNav = () => {
        setExpand(!isExpand);
    };
    const navClose = () => {
        setExpand(false)
    }

    const resNavRef = useRef(null);
    useOutsideClick(resNavRef, navClose);

    return (
        <>
            <nav ref={resNavRef} className={`${style["navbar-container"]} ${isExpand ? style["expand"] : ""}`}>
                {
                    isExpand ? <>
                        <div className={style["title-container"]}>
                            <span className={style["company-name"]}>
                                <img src="Images/svg/company-name.svg" alt="companyName" />
                            </span>
                            <span className={style["expand-btn"]} onClick={toggleNav}>
                                <NavbarClose />
                            </span>
                        </div>
                        <div className={style["link-wrapper"]}>
                            <div className={style["link-container"]}>
                                <span className={style["nav-icon"]}>
                                    <GridIcon />
                                </span>
                                <h4 className={style["menu-title"]}>Master</h4>
                            </div>
                            <Accordion className='navbar-accordion navbar-accordion-parent'>
                                <AccordionSummary
                                    expandIcon={<AccordionDrop />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <span className={`${style["nav-icon"]} nav-icon`}>
                                        <DataMenuIcon />
                                    </span>
                                    Transaction
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="menu-container">
                                        <h4 className={`${style["menu-title"]} menu-title`}>Lab Stock</h4>
                                    </div>
                                    <Accordion className='navbar-accordion-child'>
                                        <AccordionSummary
                                            expandIcon={<NestAccordionDrop />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            Lab Search
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="menu-list-wrapper">
                                                <div className="list-container tree-line">
                                                    <h5 className="nest-menu-list">Search</h5>
                                                </div>
                                                <div className="list-container tree-line">
                                                    <h5 className="nest-menu-list">Cart</h5>
                                                </div>
                                                <SideNavMenu />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </> : <>
                        <span onClick={toggleNav} className={`${style[`menu-icon`]}`}><PageIcon /></span>
                        <span className={style["menu-icon"]}><GridIcon /></span>
                        <span className={`${style[`menu-icon`]} ${style[`menu-icon-active`]}`}><DataMenuIcon /></span>
                    </>
                }
            </nav>
        </>
    );
}

export default SideNav;
