import React from 'react';
import { Chip } from '@mui/material';
import './chip_comp.scss';
import { DeleteIcon } from '../../icons';

function ChipComp({ small, label = "Label", height = 34, onDelete, white, onClick = () => { }, activeIndex, active, className, upperCase, light, rounded, resultChip, font = 13 }) {
    return (
        <Chip style={{
            height: `${height}px`,
            fontSize: `${font}px`
        }} deleteIcon={<span className='dlt-icon' onClick={onDelete}>
            <DeleteIcon />
        </span>} onDelete={onDelete} onClick={() => {
            //  (activeIndex)
            onClick(activeIndex)
        }} key={label} label={label} className={`chip-comp ${resultChip ? "result-chip" : ""} ${small ? "chip-comp-small" : "chip-comp-large"} ${white ? "bg-white" : ""}  ${className} ${upperCase ? "uppercase" : ""} ${light ? "light-bg" : ""} ${onDelete && "closable-chip"} ${active ? "active" : ""} ${rounded ? "rounded" : ""}`} />
    )
}

export default ChipComp