import React from 'react'
import style from './top_nav.module.scss';
import { NotificationIcon, SearchIcon } from '../../theme/icons';
import { Link } from '@mui/material';
import DraggableTab from '../../theme/Elements/TabComp/DraggableTab';

function TopNav() {
    return (
        <nav className={`${style["navbar-container"]}`}>
            <div className={style["left-wrapper"]}>
                <Link href="/" className={style["logo-icon"]}><img src="Images/svg/logo.svg" alt="logo" /></Link>
                <DraggableTab />
            </div>
            <div className={style["details-wrapper"]}>
                <span className={style["nav-icons"]}>
                    <SearchIcon />
                </span>
                <span className={style["nav-icons"]}>
                    <NotificationIcon />
                </span>
                <span className={style["profile-pic"]}>
                    <img src="Images/svg/user.svg" alt="user" />
                </span>

            </div>
        </nav >
    )
}


export default TopNav