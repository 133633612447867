import { Button } from '@mui/material'
import React from 'react'
import './button_comp.scss'

function ButtonComp({ children, ariaExpanded, ariahaspopup, ariaControls, secondary, prefix, tertiary, iconOnly, suffix, onClick, height, id, className, circle, counter, bgNone, linkBtn }) {
  return (
    <Button
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      aria-haspopup={ariahaspopup}
      id={id}
      onClick={onClick}
      startIcon={prefix}
      style={{
        height: height,
        width: `${iconOnly ? `${height}px` : "auto"}`
      }}
      endIcon={suffix}
      variant={secondary ? 'outlined' : 'contained'}
      className={`btn-comp ${circle && "circle"}
    ${secondary ? "secondary-btn" : linkBtn ? "link-btn" : tertiary ? "tertiary-btn" : "primary-btn"} ${iconOnly ? "icon-only" : ""} ${className} ${bgNone ? "bg-none" : ""}
    `}>
      <span className="btn-label">{children}</span>
      {counter && <span className="counter">{counter}</span>}
    </Button>
  )
}

export default ButtonComp