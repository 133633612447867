import { FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import ButtonComp from './../ButtonComp/index';
import { CheckedClose, CheckedTick, SelectDropdown, UnCheckClose, UnCheckTick } from './../../icons';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RadioSelect({ label, data, placeholder = "0 selected/0 deselected" }) {
  const [selectedNames, setSelectedNames] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const selectRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleNameChange = (event) => {
    setSelectedNames(event.target.value);
  };

  const handleClearAll = () => {
    setSelectedNames([]);
    setSelectedOptions({});
    handleClose();
  };

  const handleOptionChange = (name) => (event) => {
    const value = event.target.value.split("-")[1];
    setSelectedOptions((prev) => ({
      ...prev,
      [name]: value,
    }));
    setOpen(true);

  };


  const getSelectedCount = () => {
    return Object.values(selectedOptions).filter(
      (option) => option === "selected"
    ).length;
  };

  const getUnselectedCount = () => {
    return Object.values(selectedOptions).filter(
      (option) => option === "deselected"
    ).length;
  };


  return (
    <FormControl className='select-comp' fullWidth>
      <label className='input-label' htmlFor={`${label}-select`}>{label}</label>
      <Select
        displayEmpty
        ref={selectRef}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={() => <span className='select-arrow'><SelectDropdown /></span>}
        id={`${label}-select`}
        value={selectedNames}
        onChange={handleNameChange}
        renderValue={(selected) => {
          if (!selected || (Array.isArray(selected) && selected.length === 0)) {
            return <em className='select-placeholder'>{placeholder}</em>;
          }
          return `${getSelectedCount()} Selected/${getUnselectedCount()} Deselected`
        }}
        MenuProps={MenuProps}
      >
        <ButtonComp onClick={handleClearAll} className="clr-btn" linkBtn>Clear all</ButtonComp>
        {data.map((item) => (
          <MenuItem
            className='select-comp-popup'
            key={item.option}
            value={item.option}
            onClick={(e) => e.stopPropagation()}
          >
            <RadioGroup
              value={`${item.option}-${selectedOptions[item.option] || ""}`}
              onChange={handleOptionChange(item.option)}
              className='radio-wrapper'
            >
              <FormControlLabel
                key={`${item.option}-select`}
                value={`${item.option}-selected`}
                control={<Radio icon={<UnCheckTick />} checkedIcon={<CheckedTick />} />}
                classes={{
                  root:
                    selectedOptions[item.option] === "selected"
                      ? "selectedRadio"
                      : "deselectedRadio",
                  label: "radioLabel",
                }}
              />
              <FormControlLabel
                key={`${item.option}-deselect`}
                value={`${item.option}-deselected`}
                control={<Radio icon={<UnCheckClose />} checkedIcon={<CheckedClose />} onMouseDown={(e) => e.stopPropagation()} />}
                classes={{
                  root:
                    selectedOptions[item.option] === "selected"
                      ? "selectedRadio"
                      : "deselectedRadio",
                  label: "radioLabel",
                }}
              />
            </RadioGroup>
            <ListItemText primary={item.option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RadioSelect;
