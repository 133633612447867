import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { RadioCheckIcon, RadioUnCheckIcon } from '../../icons';
import './radio.scss';

function RadioComp({ data, onChange, active }) {
  return (
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      className='radio-comp'
      value={active}
      onChange={onChange}
    >
      {
        data.map((radio) => {
          return (
            <FormControlLabel
              key={radio.value}
              value={radio.value}
              control={
                active ? <Radio checked={radio.value === active} checkedIcon={<RadioCheckIcon />} icon={<RadioUnCheckIcon />} /> : <Radio checkedIcon={<RadioCheckIcon />} icon={<RadioUnCheckIcon />} />
              }
              label={radio.label}
            />
          );
        })
      }
    </RadioGroup>
  );
}

export default RadioComp;
