import React from 'react'
import { Checkbox } from '@mui/material'
import { CheckedCheckbox, UnCheckedCheckbox } from '../../icons'
import './checkbox_comp.scss'

function CheckBoxComp({ checked, id }) {

  return (
    <Checkbox id={id} className='checkbox-comp' checked={checked} icon={<UnCheckedCheckbox />}
      checkedIcon={<CheckedCheckbox />} />
  )
}

export default CheckBoxComp