import { TextField } from '@mui/material'
import React, { useState } from 'react'
import './input_comp.scss'

function InputComp({ id, disabled, label, validaton, type, fullWidth, placeholder, value, textArea, onChange }) {

    const [err, setErr] = useState(false)
    const handleChange = (e) => {
        onChange(e)

        if (validaton) {
            !value ? setErr(true) : setErr(false)
        }
    }

    return (
        <div className="flex flex-column gap-3 w-full">
            {
                label && <label className='input-label'>{label}</label>
            }
            <TextField error={err} helperText={err && `Incorrect entry. Please 
enter a correct value`} disabled={disabled} multiline={textArea} placeholder={placeholder} value={value} fullWidth={textArea || fullWidth} onChange={handleChange} type={type} id={id} variant="outlined" className={`input-comp ${textArea ? "textArea-comp" : ""}`} />
        </div>
    )
}

export default InputComp